var Permission = Object.freeze({
  poliza_read: 'poliza_read',
  poliza_create: 'poliza_create',
  poliza_edit: 'poliza_edit',
  poliza_destroy: 'poliza_destroy',
  poliza_send_email: 'poliza_send_email',
  poliza_send_expiration_email: 'poliza_send_expiration_email',
  poliza_upload_document: 'poliza_upload_document',
  poliza_cancel: 'poliza_cancel',
  poliza_endorsement_read: 'poliza_endorsement_read',
  poliza_endorsement_create: 'poliza_endorsement_create',
  poliza_endorsement_edit: 'poliza_endorsement_edit',
  poliza_endorsement_approve_reject: 'poliza_endorsement_approve_reject',
  claim_read: 'claim_read',
  claim_create: 'claim_create',
  claim_edit: 'claim_edit',
  claim_destroy: 'claim_destroy'
});
export var permissionsMenu = [{
  title: 'Pólizas',
  actions: [{
    title: 'VER',
    label: ['Ver Listado General de Pólizas', 'Ver Detalle de la Póliza'],
    code: Permission.poliza_read
  }, {
    title: 'CREAR',
    label: ['Crear Nueva Póliza'],
    code: Permission.poliza_create
  }, {
    title: 'EDITAR',
    label: ['Editar Póliza'],
    code: Permission.poliza_edit
  }, {
    title: 'BORRAR',
    label: ['Borrar Póliza'],
    code: Permission.poliza_destroy
  }, {
    title: 'OTROS',
    label: ['Cargar Documento de Póliza'],
    code: Permission.poliza_upload_document
  }, {
    title: 'OTROS',
    label: ['Cancelar Póliza'],
    code: Permission.poliza_cancel
  }, {
    title: 'OTROS',
    label: ['Enviar Correo de Nueva Póliza'],
    code: Permission.poliza_send_email
  }, {
    title: 'OTROS',
    label: ['Enviar Correo de Vencimiento de Póliza'],
    code: Permission.poliza_send_expiration_email
  }]
}, {
  title: 'Endosos',
  actions: [{
    title: 'VER',
    label: ['Ver Listado General de Endosos', 'Ver Listado de Endosos de una Póliza', 'Ver Detalle del Endoso'],
    code: Permission.poliza_endorsement_read
  }, {
    title: 'CREAR',
    label: ['Crear Nuevo Endoso'],
    code: Permission.poliza_endorsement_create
  }, {
    title: 'EDITAR',
    label: ['Editar Endoso'],
    code: Permission.poliza_endorsement_edit
  }, {
    title: 'OTROS',
    label: ['Cargar Documento de Endoso', 'Aprobar Endoso', 'Rechazar Endoso'],
    code: Permission.poliza_endorsement_approve_reject
  }]
}, {
  title: 'Siniestros',
  actions: [{
    title: 'VER',
    label: ['Ver Listado General de Siniestros', 'Ver Listado de Siniestros de una Póliza'],
    code: Permission.claim_read
  }, {
    title: 'CREAR',
    label: ['Crear Nuevo Siniestro'],
    code: Permission.claim_create
  }, {
    title: 'EDITAR',
    label: ['Editar Siniestro'],
    code: Permission.claim_edit
  }, {
    title: 'BORRAR',
    label: ['Borrar Siniestro'],
    code: Permission.claim_destroy
  }]
}];